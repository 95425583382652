import { gql } from '@apollo/client'

import { DriverContactDetailsFragment, DriverDetailsFragment } from 'graphql/fragments'

export const GET_DRIVER = gql`
  query GetDriver($driverId: Long!) {
    driver(driverId: $driverId) {
      ...DriverDetails
    }

    ${DriverDetailsFragment}
  }
`

export const GET_DRIVER_ORDER_DETAILS = gql`
  query GetDriverOrderDetails($driverId: Long!) {
    driver(driverId: $driverId) {
      id
      xeroPurchaseCode
      vatCategoryId
    }
  }
`

export const GET_DRIVER_CURRENCY = gql`
  query GetDriverCurrency($driverId: Long!) {
    driver(driverId: $driverId) {
      id
      currencyId
    }
  }
`

export const GET_DRIVER_BY_CURRENT_USER = gql`
  query GetDriverByCurrentUser {
    driverByCurrentUser {
      ...DriverDetails
    }
    ${DriverDetailsFragment}
  }
`
export const GET_DRIVERS = gql`
  query GetDrivers(
    $after: String
    $before: String
    $first: Int
    $last: Int
    $order: [DriverSortInput!]
    $where: DriverFilterInput
  ) {
    drivers(
      after: $after
      before: $before
      first: $first
      last: $last
      order: $order
      where: $where
    ) {
      edges {
        node {
          id
          name
          addressLine1
          addressLine2
          city
          postcode
          email
          isActive
          callSign
          minMiles
          distanceRate
          minCharge
          licenseType
          licenseExpiryDate
          courierExchangeMemberId
          country {
            id
            name
          }
          telephoneNumber
          driverType
          xeroCodeId
          xeroCode {
            id
            name
          }
          vehicles {
            id
            registrationNumber
            vehicleTypeId
            vehicleType {
              name
            }
          }
        }
      }

      totalCount
      pageInfo {
        startCursor
        endCursor
        hasPreviousPage
        hasNextPage
      }
    }
  }
`

export const GET_DRIVERS_XERO_PURCHASE_CODESS = gql`
  query GetDriversXeroPurchaseCodes($where: DriverFilterInput) {
    nonPaginatedDrivers(where: $where) {
      id
      xeroPurchaseCode
    }
  }
`

export const GET_PRIMARY_DRIVERS = gql`
  query GetPrimaryDrivers(
    $after: String
    $before: String
    $first: Int
    $last: Int
    $order: [DriverSortInput!]
    $where: DriverFilterInput
  ) {
    primaryDrivers(
      after: $after
      before: $before
      first: $first
      last: $last
      order: $order
      where: $where
    ) {
      edges {
        node {
          id
          name
          addressLine1
          addressLine2
          city
          postcode
          email
          isActive
          callSign
          minMiles
          distanceRate
          minCharge
          licenseType
          licenseExpiryDate
          currencyId
          identityUserName
          isUsingInternalApp
          averageRating
          vatCategoryId
          xeroPurchaseCode
          currency {
            id
            code
          }
          country {
            id
            name
          }
          telephoneNumber
          driverType
          xeroCode {
            id
            name
          }
          vehicles {
            id
            registrationNumber
            vehicleTypeId
            vehicleType {
              name
            }
          }
        }
      }

      totalCount
      pageInfo {
        startCursor
        endCursor
        hasPreviousPage
        hasNextPage
      }
    }
  }
`

export const GET_SUB_DRIVERS = gql`
  query GetSubDrivers(
    $driverId: Long!
    $after: String
    $before: String
    $first: Int
    $last: Int
    $order: [DriverSortInput!]
    $where: DriverFilterInput
  ) {
    subDrivers(
      driverId: $driverId
      after: $after
      before: $before
      first: $first
      last: $last
      order: $order
      where: $where
    ) {
      edges {
        node {
          id
          name
          callSign
          email
          telephoneNumber
          identityUserName
          isUsingInternalApp
          isActive
          xeroCode {
            id
            name
          }
        }
      }

      totalCount
      pageInfo {
        startCursor
        endCursor
        hasPreviousPage
        hasNextPage
      }
    }
  }
`

export const GET_DRIVER_CONTACTS = gql`
  query GetDriverContacts(
    $driverId: Long!
    $after: String
    $before: String
    $first: Int
    $last: Int
    $order: [DriverContactSortInput!]
    $where: DriverContactFilterInput
  ) {
    driverContacts(
      driverId: $driverId
      after: $after
      before: $before
      first: $first
      last: $last
      order: $order
      where: $where
    ) {
      edges {
        node {
          id
          name
          department
          telephoneNumber
          email
        }
      }

      totalCount
      pageInfo {
        startCursor
        endCursor
        hasPreviousPage
        hasNextPage
      }
    }
  }
`

export const GET_DRIVER_CONTACT = gql`
  query GetDriverContact($driverContactId: Long!) {
    driverContact(driverContactId: $driverContactId) {
      ...DriverContactDetails
    }
    ${DriverContactDetailsFragment}
  }
`

export const GET_ALLOCATED_DRIVERS = gql`
  query GetAllocatedDrivers {
    allocatedDrivers {
      id
      driver {
        id
      }
    }
  }
`

export const GET_DRIVERS_PAY_DETAILS = gql`
  query GetDriversPayDetails($driverId: Long!) {
    driver(driverId: $driverId) {
      id
      nationalInsuranceNumber
      paymentMethodId
      bankName
      bankAccountNumber
      isGenerateSeparateInvoice
      vatIdNumber
      bankSortCode
      employeeReference
      isEmailInvoice
      chequeName
      payFrequency
      creditCard
    }
  }
`

export const GET_DRIVER_OTHER_DETAILS = gql`
  query GetDriverOtherDetails($driverId: Long!) {
    driver(driverId: $driverId) {
      id
      licenseType
      licenseExpiryDate
      endorsements
    }
  }
`

export const GET_BOOKING_DRIVERS_LOCATION = gql`
  query GetDriversLocations($bookingId: Long!) {
    driversLocations(bookingId: $bookingId) {
      id
      driverId
      longitude
      latitude
      accuracy
      createdAt
      lastModifiedAt
    }
  }
`

export const GET_BOOKING_DRIVERS_LOCATION_BY_TRACKING_NUMBER = gql`
  query GetDriversLocationsByTrackingNumber($trackingGuid: UUID!) {
    driversLocationsByTrackingNumber(trackingGuid: $trackingGuid) {
      id
      driverId
      longitude
      latitude
      accuracy
      createdAt
      lastModifiedAt
    }
  }
`

export const GET_PAYMENT_TERMS_QUERY = gql`
  query getPaymentTerms {
    paymentTerms {
      id
      paymentTerm
      description
    }
  }
`

export const GET_PAGED_DRIVER_INVOICES = gql`
  query GetDriverInvoices(
    $driverId: Long!
    $after: String
    $before: String
    $first: Int
    $last: Int
    $order: [BookingInvoiceSortInput!]
    $where: BookingInvoiceFilterInput
  ) {
    pagedDriverInvoices(
      driverId: $driverId
      after: $after
      before: $before
      first: $first
      last: $last
      order: $order
      where: $where
    ) {
      edges {
        node {
          id
          invoiceNumber
          invoiceDate
          dueDate
          bookingId
          booking {
            id
            ourReference
          }
          poAddressLine1
          driverInvoiceNumber
        }
      }

      totalCount
      pageInfo {
        startCursor
        endCursor
        hasPreviousPage
        hasNextPage
      }
    }
  }
`

export const GET_DRIVER_PROFILE = gql`
  query getDriverProfile($driverId: Long!) {
    driver(driverId: $driverId) {
      id
      identityUserName
      userProfile {
        id
        firstName
        lastName
        fullName
      }
    }
  }
`

export const GET_DRIVER_PROFILE_BY_USER = gql`
  query getDriverProfileByUser {
    driverProfile {
      id
    }
  }
`
export const GET_DRIVERS_CURRENT_LOCATION_DATA = gql`
  query currentDriversLocationsGrid(
    $after: String
    $before: String
    $first: Int
    $last: Int
    $order: [DriverLocationSortInput!]
    $where: DriverLocationFilterInput
  ) {
    currentDriversLocations(
      after: $after
      before: $before
      first: $first
      last: $last
      order: $order
      where: $where
    ) {
      edges {
        node {
          id
          driverId
          latitude
          longitude
          lastModifiedAt
          createdAt
          driverTrackingStatus {
            allocated
            nextStopPostCode
            nextStopDateTime
            nextStopLiveEta
          }
          driver {
            id
            name
          }
        }
      }
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasPreviousPage
        hasNextPage
      }
    }
  }
`

export const GET_DRIVER_NEXT_ROUTE_MAPS = gql`
  query getDriverNextRoute($driverId: Long!) {
    driverNextRoute(driverId: $driverId) {
      id
      longitude
      latitude
      nextStopGoogleMapsPlaceId
    }
  }
`

export const GET_BOOKING_DRIVER_RATING = gql`
  query getBookingDriverRating($bookingId: Long!, $driverId: Long!) {
    bookingDriverRating(bookingId: $bookingId, driverId: $driverId) {
      id
      bookingId
      rating
      ratingComment
      driver {
        id
        name
      }
    }
  }
`

export const GET_DRIVER_AVERAGE_RATING = gql`
  query GetDriverRating($driverId: Long!) {
    driver(driverId: $driverId) {
      id
      averageRating
    }
  }
`

export const GET_DRIVER_ALLOCATIONS = gql`
  query GetDriverAllocations(
    $driverId: Long!
    $after: String
    $before: String
    $first: Int
    $last: Int
    $order: [BookingDriverSortInput!]
    $where: BookingDriverFilterInput
  ) {
    pagedBookingDriverAllocations(
      driverId: $driverId
      after: $after
      before: $before
      first: $first
      last: $last
      order: $order
      where: $where
    ) {
      edges {
        node {
          id
          bookingId
          rating
          ratingComment
          createdBy
          lastModifiedBy
        }
      }
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasPreviousPage
        hasNextPage
      }
    }
  }
`
