import { useMemo, useEffect } from 'react'

import { useOidcIdToken } from '@axa-fr/react-oidc-context'
import { Box, Grid } from '@mui/material'
import { GridEventListener } from '@mui/x-data-grid'
import { format, parseJSON } from 'date-fns'
import { useForm } from 'react-hook-form'

import { ControlledSelector, FplDataGrid, IGridColumn, ISelectorOptions } from 'components/common'
import { DATE_FORMAT } from 'constants/index'
import { TaskFilterInput, useGetTaskCategoriesQuery, useGetTaskUsersQuery } from 'generated/graphql'
import { GET_TASKS_QUERY } from 'graphql/queries'
import { taskBoardAssignedUserNameVar } from 'graphql/reactiveVariables'
import { TaskDialogOptions, useDialog } from 'providers'

const columns: IGridColumn[] = [
  { field: 'id', headerName: 'Id', width: 100 },
  { field: 'description', headerName: 'Description', minWidth: 200, flex: 1 },
  {
    field: 'assignedUserName',
    headerName: 'Assigned',
    minWidth: 150,
    flex: 0.5,
    valueGetter: ({ row }) => {
      return row.assignedUser
    },
  },
  { field: 'status', headerName: 'Status', minWidth: 100, flex: 0.5 },
  {
    field: 'bookingId',
    headerName: 'Booking',
    minWidth: 100,
    flex: 0.5,
    valueGetter: ({ row }) => {
      return row.booking ? row.booking.ourReference : ''
    },
  },
  {
    field: 'categoryId',
    headerName: 'Category',
    minWidth: 100,
    flex: 0.5,
    valueGetter: ({ row }) => {
      return row.category?.name ?? ''
    },
  },
  {
    field: 'endDate',
    headerName: 'End Date',
    minWidth: 100,
    flex: 0.5,
    valueFormatter: ({ value }) => {
      return value && format(parseJSON(value as string), DATE_FORMAT)
    },
  },
  { field: 'time', headerName: 'Time', minWidth: 100, flex: 0.5 },
]

const Board = () => {
  const { dialogOpen } = useDialog<TaskDialogOptions>()
  const { idTokenPayload } = useOidcIdToken()

  const { control, watch, setValue } = useForm({
    shouldUnregister: true,
    defaultValues: {
      userName: taskBoardAssignedUserNameVar() || idTokenPayload.name,
      taskCategoryId: '',
    },
  })

  const taskCategoryIdValue = watch('taskCategoryId')
  const userNameValue = watch('userName')

  // queries
  const { data: taskCategoryData } = useGetTaskCategoriesQuery()

  const { data: usersData } = useGetTaskUsersQuery()

  const categoryOptions: ISelectorOptions = useMemo(
    () =>
      taskCategoryData?.taskCategories?.map((tc) => ({
        value: tc.id,
        label: tc.name,
      })) || [],
    [taskCategoryData],
  )

  const userOptions: ISelectorOptions = useMemo(
    () =>
      usersData?.taskUsers.map((taskUser) => ({
        value: taskUser.userName,
        label: `${taskUser.firstName} ${taskUser.lastName}`,
      })) || [],
    [usersData],
  )

  useEffect(() => {
    const taskBoardAssignedUserNameVarValue = taskBoardAssignedUserNameVar()
    if (taskBoardAssignedUserNameVarValue) {
      setValue('userName', taskBoardAssignedUserNameVarValue)
    }
  }, [setValue])

  useEffect(() => {
    const taskBoardAssignedUserNameVarValue = taskBoardAssignedUserNameVar()
    if (taskBoardAssignedUserNameVarValue !== userNameValue) {
      taskBoardAssignedUserNameVar(userNameValue)
    }
  }, [userNameValue])

  // handlers
  const handleRowClick: GridEventListener<'rowClick'> = (params) => {
    dialogOpen({
      id: Number(params.id),
      bookingId: params.row.bookingId,
    })
  }

  const where: TaskFilterInput | undefined = useMemo(() => {
    if (taskCategoryIdValue || userNameValue) {
      return {
        and: [
          ...(taskCategoryIdValue ? [{ categoryId: { eq: Number(taskCategoryIdValue) } }] : []),
          ...(userNameValue ? [{ assignedUserName: { eq: userNameValue } }] : []),
        ],
      }
    }
  }, [taskCategoryIdValue, userNameValue])

  return (
    <Box>
      <FplDataGrid
        query={GET_TASKS_QUERY}
        entityName='tasks'
        filter={where}
        toolbar={{
          caption: 'Tasks',
          rightSide: (
            <>
              <Grid item>
                <Box width='200px'>
                  <ControlledSelector
                    control={control}
                    name='userName'
                    label='Assigned To'
                    options={userOptions}
                    defaultValue=''
                    emptyValue={{ value: '', label: 'All Users' }}
                    displayEmpty
                    size='small'
                  />
                </Box>
              </Grid>

              <Grid item>
                <Box width='160px'>
                  <ControlledSelector
                    control={control}
                    name='taskCategoryId'
                    label='Task Category'
                    options={categoryOptions}
                    defaultValue=''
                    emptyValue={{ value: '', label: 'All Categories' }}
                    displayEmpty
                    size='small'
                  />
                </Box>
              </Grid>
            </>
          ),
        }}
        columns={columns}
        onRowClick={handleRowClick}
      />
    </Box>
  )
}

export default Board
