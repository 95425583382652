import { SelectDriversActionKind } from 'constants/EntityConstants/SelectDriversAction'
import { DEBOUNCE_DELAY, DriverPurchaseCodes, VatCategoryType } from 'constants/index'

import { useCallback, useEffect, useMemo, useReducer, useState } from 'react'

import CloseIcon from '@mui/icons-material/Close'
import { alpha, Grid, IconButton, Skeleton, Stack } from '@mui/material'
import { GridRowId, GridRowModesModel, GridRowSelectionModel } from '@mui/x-data-grid'
import SelectDialogComponent from 'components/bookings/BookingDetails/DriverDetails/AllocateDrivers/SelectDriverDetails/DialogComponent'
import {
  ConfirmDialog,
  ControlledSelector,
  ControlledTextField,
  FplDataGrid,
} from 'components/common'
import {
  CreateAndUpdateBookingDriverDtoInput,
  Driver,
  DriverFilterInput,
  GetBookingDocument,
  GetBookingQuery,
  GetDriverBidsDocument,
  SortEnumType,
  useCreateBookingDriversMutation,
  useGetBookingAllocatedDriversQuery,
  useGetCurrenciesQuery,
  useGetDriverBidsQuery,
  useGetDriverContactLazyQuery,
  useGetDriverLazyQuery,
  useGetVehicleLazyQuery,
  useGetXeroCodesQuery,
  XeroCode,
} from 'generated/graphql'
import {
  GET_BOOKING_ALLOCATED_DRIVERS,
  GET_PAGED_BOOKING_ALLOCATED_DRIVERS,
  GET_PRIMARY_DRIVERS,
} from 'graphql/queries'
import { removePagination } from 'helpers'
import { selectingDriversReducer } from 'helpers/selectDeriverDetails.helpers'
import useGetSelectDriverDetailsColumns from 'hooks/fplDataGridColumns/useGetSelectDriverDetailsColumns'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import { useDebounce } from 'use-debounce'

import CustomGridFooter from './CustomGridFooter'
import { DriverInstruction, EditedDriverRow, SelectedDriver } from './types'

interface IProps {
  booking?: GetBookingQuery['booking']
  onAllocateDriverSuccessfully: () => void
}

export type SelectDriversState = {
  drivers: SelectedDriver[]
  rowMode: GridRowModesModel
  focusedDriverId: string | null
  selectVehicle?: boolean
  selectSubDriver?: boolean
  selectContact?: boolean
  selectInstruction?: boolean
}

const getXeroCodeValues = (xeroCodes: XeroCode[] = []) => {
  return xeroCodes.map((c) => ({ value: c.id, label: c.name }))
}

// ToDo: refactor. To many states
const SelectDriverDetails = (props: IProps) => {
  const { booking, onAllocateDriverSuccessfully } = props
  const [selectedDrivers, dispatchAllocatedDrivers] = useReducer(selectingDriversReducer, {
    drivers: [] as SelectedDriver[],
    rowMode: {} as GridRowModesModel,
    focusedDriverId: null,
    selectVehicle: false,
    selectSubDriver: false,
    selectContact: false,
    selectInstruction: false,
  })

  const [selectionModel, setSelectionModel] = useState<GridRowSelectionModel>([])

  const { control, watch, setValue } = useForm({
    defaultValues: {
      xeroCodeId: '',
      search: '',
    },
  })
  const [searchInput] = useDebounce(watch('search', ''), DEBOUNCE_DELAY)
  const searchValue = searchInput.length >= 3 ? searchInput : ''
  const xeroCodeIdValue: any = watch('xeroCodeId')
  const entityName = 'primaryDrivers'

  useEffect(() => {
    if (watch('search', '') !== '') {
      removePagination(entityName)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch('search')])

  // queries
  const { data: xeroCodesData, loading: xeroCodeLoading } = useGetXeroCodesQuery()

  // ToDo: create dedicated query
  const [getDriver, { loading: driverLoading }] = useGetDriverLazyQuery()

  // ToDo: create dedicated query
  const [getVehicle, { loading: vehicleLoading }] = useGetVehicleLazyQuery()

  // ToDo: create dedicated query
  const [getDriverContact, { loading: driverContactLoading }] = useGetDriverContactLazyQuery()

  // ToDo: create dedicated query
  const { data: bookingAllocatedDrivers } = useGetBookingAllocatedDriversQuery({
    variables: { bookingId: Number(booking?.id) },
  })

  const { data: currenciesData } = useGetCurrenciesQuery()

  const [allocateDrivers, { loading: allocatingDrivers }] = useCreateBookingDriversMutation({
    refetchQueries: [
      { query: GetBookingDocument, variables: { bookingId: Number(booking?.id) } },
      // ToDo: Inefficient. Use manual cache update.
      { query: GET_BOOKING_ALLOCATED_DRIVERS, variables: { bookingId: Number(booking?.id) } },
      {
        query: GET_PAGED_BOOKING_ALLOCATED_DRIVERS,
        variables: {
          bookingId: Number(booking?.id),
          order: { createdAt: SortEnumType.Desc },
          after: null,
          before: null,
          first: 5,
          last: null,
        },
      },
      {
        query: GET_PAGED_BOOKING_ALLOCATED_DRIVERS,
        variables: {
          bookingId: Number(booking?.id),
          order: { createdAt: SortEnumType.Desc },
          after: null,
          before: null,
          first: 100,
          last: null,
        },
      },
      {
        query: GetDriverBidsDocument,
        variables: { where: { bookingId: { eq: Number(booking?.id) } } },
      },
    ],
    onCompleted: (data) => {
      dispatchAllocatedDrivers({
        type: SelectDriversActionKind.CLEAR_PAYLOAD,
        payload: {},
      })

      if (data.createBookingDrivers?.length > 0) {
        toast.success(`Drivers were allocated with success.`)
      } else {
        toast.error(`Something went wrong. Please try again later.`)
      }
      onAllocateDriverSuccessfully()
    },
  })

  const { data: bidsData, loading: driverBidsLoading } = useGetDriverBidsQuery({
    variables: {
      where: {
        bookingId: { eq: Number(booking?.id) },
      },
    },
    nextFetchPolicy: 'network-only',
    skip: !booking?.id,
  })

  const xeroCodes = useMemo(
    () => getXeroCodeValues(xeroCodesData?.xeroCodes as XeroCode[]),
    [xeroCodesData],
  )

  const allocatedDrivers = bookingAllocatedDrivers?.bookingAllocatedDrivers
  const allocatedDriverIds = useMemo(
    () => allocatedDrivers?.map((bookingDriver) => bookingDriver.driver.id) || [],
    [allocatedDrivers],
  )

  const currencyItems = useMemo(
    () =>
      currenciesData?.currencies.map((currency) => ({
        value: currency.id,
        label: currency.code,
      })) || [],
    [currenciesData],
  )

  useEffect(() => {
    if (selectedDrivers.drivers.length > 0) {
      const ids = selectedDrivers.drivers.map((driver) => driver.driverId)
      setSelectionModel(ids)
    } else {
      setSelectionModel([])
    }
  }, [selectedDrivers.drivers])

  // handlers
  const handleShowVehicles = useCallback(
    (id: GridRowId) => () => {
      dispatchAllocatedDrivers({
        type: SelectDriversActionKind.SELECT_VEHICLE,
        payload: { driverId: String(id) },
      })
    },
    [],
  )

  const handleShowSubDrivers = useCallback(
    (id: GridRowId) => () => {
      dispatchAllocatedDrivers({
        type: SelectDriversActionKind.SELECT_SUB_DRIVER,
        payload: { driverId: String(id) },
      })
    },
    [],
  )

  const handleShowContacts = useCallback(
    (id: GridRowId) => () => {
      dispatchAllocatedDrivers({
        type: SelectDriversActionKind.SELECT_CONTACT,
        payload: { driverId: String(id) },
      })
    },
    [],
  )

  const handleShowInstruction = useCallback(
    (id: GridRowId) => () => {
      dispatchAllocatedDrivers({
        type: SelectDriversActionKind.SELECT_INSTRUCTION,
        payload: { driverId: String(id) },
      })
    },
    [],
  )

  const handleSelectVehicle = (id: number) => {
    getVehicle({
      variables: { vehicleId: Number(id), driverId: Number(selectedDrivers.focusedDriverId) },
    }).then((result) => {
      if (!result.data?.vehicle) return
      dispatchAllocatedDrivers({
        type: SelectDriversActionKind.SET_VEHICLE,
        payload: {
          selectedVehicleId: String(id),
          selectedVehicleName: result.data.vehicle.vehicleType.name,
        },
      })
    })
  }

  const handleSelectSubDriver = (id: number) => {
    getDriver({ variables: { driverId: Number(id) } }).then((result) => {
      if (!result.data?.driver) return
      dispatchAllocatedDrivers({
        type: SelectDriversActionKind.SET_SUB_DRIVER,
        payload: {
          selectedSubDriverId: String(id),
          selectedSubDriverName: result.data.driver.name,
        },
      })
    })
  }

  const handleSelectContact = (id: number) => {
    getDriverContact({
      variables: { driverContactId: Number(id) },
    }).then((result) => {
      if (!result.data?.driverContact) return

      dispatchAllocatedDrivers({
        type: SelectDriversActionKind.SET_CONTACT,
        payload: {
          selectedContactId: String(id),
          selectedContactName: result.data.driverContact.name,
        },
      })
    })
  }

  const handleSelectInstruction = (data: DriverInstruction) => {
    dispatchAllocatedDrivers({
      type: SelectDriversActionKind.SET_INSTRUCTION,
      payload: {
        ...data,
      },
    })
  }

  const handleClearClick = () => {
    dispatchAllocatedDrivers({
      type: SelectDriversActionKind.CLEAR_PAYLOAD,
      payload: {},
    })
  }

  const [gridRows, setGridRows] = useState<EditedDriverRow[]>([])

  const handleOnSaveClick = (rows: EditedDriverRow[] | undefined) => {
    const { drivers } = selectedDrivers as SelectDriversState
    const editedRows = rows ?? gridRows

    if (booking?.id && editedRows.length > 0) {
      dispatchAllocatedDrivers({
        type: SelectDriversActionKind.SUBMIT,
        payload: {},
      })

      let inputs: CreateAndUpdateBookingDriverDtoInput[] = []

      editedRows.forEach((row) => {
        const selectedDriver = drivers.find((d) => d.driverId === row.driverId)

        if (selectedDriver) {
          const driverId = selectedDriver.selectedSubDriverId || selectedDriver.driverId
          const charge = Number(row.minCharge)
          const extraPay = Number(row.extraPay)
          const currencyId = String(row.currencyId)
          const vehicleId = selectedDriver.selectedVehicleId

          inputs = inputs.concat({
            bookingId: booking.id,
            driverId: driverId,
            charge: charge,
            extraPayment: extraPay,
            vehicleId,
            currencyId,
            distance: 0,
            isShownInstruction: selectedDriver.isShownInstruction ?? false,
            instruction: selectedDriver.instruction ?? null,
          })
        }
      })

      allocateDrivers({
        variables: {
          input: inputs,
        },
      })
    }
  }

  const [confirmOpen, setConfirmOpen] = useState(false)
  const checkOnBidsIntersection = (drivers: SelectedDriver[]) => {
    if (!bidsData) {
      return false
    }
    const intersected = bidsData.driverBids.filter((x) => {
      const arr = drivers.filter((driver) => Number(driver.driverId) === x.driverId)
      return !(arr.length === 0)
    })
    return intersected.length > 0
  }

  const handlePaymentsDetails = (driver: Driver) => {
    // Warning while allocation: when the PurchaseCode doesn't match the VAT category
    if (!driver.vatCategoryId) {
      toast.warn(
        `The VAT category for the selected driver ${driver.name} is missing. Please consider filling in the VAT data to avoid payment issues.`,
      )
    }

    if (
      driver.vatCategoryId === String(VatCategoryType.t1) &&
      driver.xeroPurchaseCode !== DriverPurchaseCodes.vatRegisteredPurchaseCode
    ) {
      toast.warn(
        'Please review the introduced purchase code as it is different from the one associated to VAT registered suppliers',
      )
    }

    if (
      driver.vatCategoryId === String(VatCategoryType.t0) &&
      driver.xeroPurchaseCode !== DriverPurchaseCodes.nonVatRegisteredPurchaseCode
    ) {
      toast.warn(
        'Please review the introduced purchase code as it is different from the one associated to not VAT registered suppliers',
      )
    }
  }

  const handleCheckBidsAllocation = (rows: EditedDriverRow[]) => {
    setGridRows(rows)
    const { drivers } = selectedDrivers as SelectDriversState

    if (drivers && bidsData && checkOnBidsIntersection(drivers)) {
      setConfirmOpen(true)
    } else {
      handleOnSaveClick(rows)
    }
  }

  const handleSelectionModelChange = (newSelectionModel: GridRowSelectionModel) => {
    if (newSelectionModel.length === 0) {
      const driversToRemove = selectedDrivers.drivers.map((d) => d.driverId)
      driversToRemove.forEach((driverId) => {
        dispatchAllocatedDrivers({
          type: SelectDriversActionKind.REMOVE,
          payload: {
            driverId: driverId,
          },
        })
      })

      setSelectionModel(selectionModel)
    } else {
      const driversToDelete = selectedDrivers.drivers.filter(
        (driver) => !newSelectionModel.includes(driver.driverId),
      )
      driversToDelete.forEach((driver) => {
        dispatchAllocatedDrivers({
          type: SelectDriversActionKind.REMOVE,
          payload: {
            driverId: driver.driverId,
          },
        })
      })

      const driversToAdd = newSelectionModel.filter(
        (driverId) => !selectedDrivers.drivers.map((d) => d.driverId).includes(driverId),
      )
      driversToAdd.forEach((driverId) => {
        dispatchAllocatedDrivers({
          type: SelectDriversActionKind.ADD,
          payload: {
            driverId: String(driverId),
          },
        })

        getDriver({ variables: { driverId: Number(driverId) } }).then((res) => {
          const driver = res.data?.driver
          if (driver) {
            handlePaymentsDetails(driver as Driver)

            dispatchAllocatedDrivers({
              type: SelectDriversActionKind.CHANGE,
              payload: {
                driverId,
                xeroPurchaseCode: driver.xeroPurchaseCode,
                driverName: driver.name,
              },
            })
          }
        })
      })
    }
  }

  const handleCloseSelectVehicleDialog = () => {
    dispatchAllocatedDrivers({
      type: SelectDriversActionKind.CLOSE_MODALS,
      payload: {},
    })
  }

  const handleCloseSelectSubDriverDialog = () => {
    dispatchAllocatedDrivers({
      type: SelectDriversActionKind.CLOSE_MODALS,
      payload: {},
    })
  }

  const handleCloseSelectDriverContactDialog = () => {
    dispatchAllocatedDrivers({
      type: SelectDriversActionKind.CLOSE_MODALS,
      payload: {},
    })
  }

  const handleCloseSelectInstructionDialog = () => {
    dispatchAllocatedDrivers({
      type: SelectDriversActionKind.CLOSE_MODALS,
      payload: {},
    })
  }

  const handleSearchClearClick = () => {
    setValue('search', '')
  }

  const isRowSelectable = useCallback(
    ({ id }: { id: GridRowId }) => !allocatedDriverIds.includes(String(id)),
    [allocatedDriverIds],
  )

  const isInstructionEditable = useCallback(
    ({ id }: { id: GridRowId }) =>
      !allocatedDriverIds.includes(String(id)) &&
      selectedDrivers.drivers.map((d) => d.driverId).includes(String(id)),
    [allocatedDriverIds, selectedDrivers.drivers],
  )

  const bookingDistance = booking?.distance

  const columns = useGetSelectDriverDetailsColumns(
    bookingDistance,
    currencyItems,
    isRowSelectable,
    handleShowVehicles,
    handleShowSubDrivers,
    handleShowContacts,
    isInstructionEditable,
    handleShowInstruction,
  )

  const filter: DriverFilterInput = useMemo(() => {
    const xeroCodeIdFilter = xeroCodeIdValue ? { xeroCodeId: { eq: Number(xeroCodeIdValue) } } : {}

    return {
      or: [{ name: { contains: searchValue } }, { callSign: { contains: searchValue } }],
      and: [{ ...xeroCodeIdFilter, isActive: { eq: true } }],
    }
  }, [xeroCodeIdValue, searchValue])

  return (
    <>
      <Stack spacing={3}>
        <FplDataGrid
          query={GET_PRIMARY_DRIVERS}
          entityName={entityName}
          columns={columns}
          filter={filter}
          defaultOrder={{ field: 'name', sort: 'asc' }}
          pageOptions={{ pageSize: 5, rowsPerPage: [5, 10] }}
          toolbar={{
            caption: 'Drivers and Vehicles',
            rightSide: (
              <>
                <Grid item sm={2.5}>
                  {xeroCodeLoading ? (
                    <Skeleton animation='wave' />
                  ) : (
                    <ControlledSelector
                      control={control}
                      name='xeroCodeId'
                      label='Service Code'
                      displayEmpty
                      size='small'
                      options={xeroCodes}
                      emptyValue={{ value: '', label: 'All Service Codes' }}
                    />
                  )}
                </Grid>
                <Grid item sm={2.5}>
                  <ControlledTextField
                    control={control}
                    name='search'
                    label='Search'
                    defaultValue=''
                    size='small'
                    endAdornment={
                      searchInput && (
                        <IconButton size='small' onClick={handleSearchClearClick}>
                          <CloseIcon fontSize='small' />
                        </IconButton>
                      )
                    }
                  />
                </Grid>
              </>
            ),
          }}
          footer={{
            component: CustomGridFooter,
            componentProps: {
              selectedDrivers: selectedDrivers.drivers,
              loading: driverLoading || vehicleLoading || driverContactLoading || driverBidsLoading,
              onClearClick: handleClearClick,
              onSaveClick: handleCheckBidsAllocation,
              processing: allocatingDrivers,
            },
          }}
          checkboxSelection
          disableSelectionOnClick
          selectionModel={selectionModel}
          onSelectionModelChange={handleSelectionModelChange}
          isRowSelectable={isRowSelectable}
          rowModesModel={selectedDrivers.rowMode}
          rootSx={{
            '& .MuiDataGrid-row.Mui-selected.MuiDataGrid-row--editing .MuiDataGrid-cell:not(.MuiDataGrid-cell--editable)':
              {
                backgroundColor: (theme) =>
                  alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
              },
            height: 'auto',
          }}
        />
      </Stack>

      <SelectDialogComponent
        selectedDrivers={selectedDrivers}
        onSelectVehicle={handleSelectVehicle}
        onCloseVehicleDialog={handleCloseSelectVehicleDialog}
        onSelectSubDriver={handleSelectSubDriver}
        onCloseSubDriverDialog={handleCloseSelectSubDriverDialog}
        onSelectContact={handleSelectContact}
        onCloseDriverContactDialog={handleCloseSelectDriverContactDialog}
        customerId={booking?.customerId}
        onSelectInstruction={handleSelectInstruction}
        onCloseInstructionDialog={handleCloseSelectInstructionDialog}
      />
      <ConfirmDialog
        open={confirmOpen}
        onConfirm={handleOnSaveClick}
        setOpen={setConfirmOpen}
        title={`Warning`}
        message={`The driver has placed a bid for this booking. Please review and allocate accordingly.`}
        option1='Cancel'
        option2='Proceed with allocation'
      />
    </>
  )
}

export default SelectDriverDetails
