import { addHours, subMonths } from 'date-fns'

import { ISelectorOptions } from 'components/common'
import { BookingFilterInput, BookingStatus, CalculatedStatus } from 'generated/graphql'

const HoursForValidUntil = 48
export const DefaultAcceptancePeriod = addHours(new Date(), HoursForValidUntil)

export const DefaultDropCharge = 30

export const InProgressBookingStatusValues: ISelectorOptions = [
  { value: CalculatedStatus.Waiting, label: 'Awaiting allocation' },
  { value: CalculatedStatus.Allocated, label: 'Allocated' },
  { value: CalculatedStatus.PackageOnBoardOnWay, label: 'In-transit' },
  { value: CalculatedStatus.PackageOnBoardArrival, label: 'Collection arrival' },
  { value: CalculatedStatus.PackageOnBoardPartial, label: 'Part collected' },
  { value: CalculatedStatus.PackageOnBoard, label: 'Collected' },
  { value: CalculatedStatus.ProofOfDelivery, label: 'Delivered' },
  { value: CalculatedStatus.ProofOfDeliveryOnWay, label: 'In-transit' },
  {
    value: CalculatedStatus.ProofOfDeliveryArrival,
    label: 'Delivery arrival',
  },
  { value: CalculatedStatus.ProofOfDeliveryPartial, label: 'Part delivered' },
  { value: CalculatedStatus.EBooking, label: 'eBooking' },
  { value: CalculatedStatus.IsPending, label: 'Pending' },
  { value: CalculatedStatus.OnHold, label: 'On Hold' },
  { value: CalculatedStatus.Declined, label: 'Declined' },
]

export const CompleteBookingStatusValues: ISelectorOptions = [
  { value: CalculatedStatus.Complete, label: 'Complete' },
  { value: CalculatedStatus.IsArchived, label: 'Archived' },
  { value: CalculatedStatus.IsCancelledOrQuoteLost, label: 'Cancelled or Quote Lost' },
]

export const QuoteStatusValues: ISelectorOptions = [
  { value: CalculatedStatus.Quotation, label: 'Quote' },
  { value: CalculatedStatus.IsQuickQuote, label: 'Quick Quote' },
]

export enum BookingPeriod {
  Today = 'TODAY',
  Yesterday = 'YESTERDAY',
  Last7Days = 'LAST_7_DAYS',
  Last30Days = 'LAST_30_DAYS',
  ThisMonth = 'THIS_MONTH',
  LastMonth = 'LAST_MONTH',
  CustomRange = 'CUSTOM_RANGE',
  AnyTime = 'ANY_TIME',
}

export const BookingPeriodValues: ISelectorOptions = [
  { value: BookingPeriod.Today, label: 'Today' },
  { value: BookingPeriod.Yesterday, label: 'Yesterday' },
  { value: BookingPeriod.Last7Days, label: 'Last 7 Days' },
  { value: BookingPeriod.Last30Days, label: 'Last 30 Days' },
  { value: BookingPeriod.ThisMonth, label: 'This Month' },
  { value: BookingPeriod.LastMonth, label: 'Last Month' },
  { value: BookingPeriod.CustomRange, label: 'Custom Range' },
]

const DefaultTakeOnPeriod = 1

export const PreBookedFilterInput: BookingFilterInput = {
  status: { nin: [BookingStatus.Quotation, BookingStatus.ScheduledTemplate] },
  isCancelledOrQuoteLost: { eq: false },
  isPreBooked: { eq: true },
}

export const InProgressFilterInput: BookingFilterInput = {
  status: {
    in: [
      BookingStatus.Waiting,
      BookingStatus.Allocated,
      BookingStatus.ProofOfDeliveryOnWay,
      BookingStatus.ProofOfDeliveryArrival,
      BookingStatus.ProofOfDeliveryPartial,
      BookingStatus.ProofOfDelivery,
      BookingStatus.PackageOnBoard,
      BookingStatus.PackageOnBoardOnWay,
      BookingStatus.PackageOnBoardArrival,
      BookingStatus.PackageOnBoardPartial,
      BookingStatus.EBooking,
      BookingStatus.Declined,
    ],
  },
  isCancelledOrQuoteLost: { eq: false },
  isArchived: { eq: false },
  isPreBooked: { eq: false },
}

export const CompleteFilterInput: BookingFilterInput = {
  or: [
    { status: { eq: BookingStatus.Complete } },
    { status: { eq: BookingStatus.Invoice } },
    { isCancelledOrQuoteLost: { eq: true } },
    { isArchived: { eq: true } },
  ],
  takenOn: {
    gte: subMonths(new Date(), DefaultTakeOnPeriod),
  },
}

export const QuoteFilterInput: BookingFilterInput = {
  and: [{ status: { eq: BookingStatus.Quotation } }, { isCancelledOrQuoteLost: { eq: false } }],
}

export const ScheduledTemplateFilterInput: BookingFilterInput = {
  status: { eq: BookingStatus.ScheduledTemplate },
}
